<script lang="ts" setup>
const data = await useLayout();

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: "id",
  addSeoAttributes: true,
});
</script>

<template>
  <Html :lang="head.htmlAttrs?.lang" :dir="head.htmlAttrs?.dir">
    <Head>
      <template v-for="link in head.link" :key="link.id">
        <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>
    </Head>
    <Body class="flex flex-col">
      <header v-if="data">
        <HeaderStandardTop :data="data" class="hidden md:block" />
        <HeaderMobileMain :nav-items="data.mainNavigation" class="md:hidden" />
      </header>

      <div class="mt-7xl hidden justify-center md:flex">
        <NuxtLinkLocale to="https://www.vanuxeem.com">
          <NuxtImg preload src="/logos/vanuxeem.svg" alt="Vanuxeem Logo" title="Vanuxeem" class="h-[60px]" />
        </NuxtLinkLocale>
      </div>

      <main class="flex-1">
        <slot />
      </main>

      <FooterBase />
    </Body>
  </Html>
</template>

<style></style>
